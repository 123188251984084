import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button,
  Typography,
  Box,
  Divider,
  Link,
  useTheme,
  useMediaQuery,
  IconButton,
  Chip
} from '@mui/material';
import { Booking, Office } from 'shared-lib';
import CloseIcon from '@mui/icons-material/Close';
import { getStatusText, getStatusColor } from 'shared-lib';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LockIcon from '@mui/icons-material/Lock';

interface BookingDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  booking: Booking;
  office: Office;
}

const BookingDetailsDialog: React.FC<BookingDetailsDialogProps> = ({
  open,
  onClose,
  booking,
  office,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatDate = (date: Date, timezone: string) => {
    return new Date(date).toLocaleDateString('ru-RU', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: timezone
    });
  };

  const formatTimeRange = (startDate: Date, endDate: Date, timezone: string) => {
    const options = { hour: '2-digit' as const, minute: '2-digit' as const, timeZone: timezone };
    return `${new Date(startDate).toLocaleTimeString('ru-RU', options)} - ${new Date(endDate).toLocaleTimeString('ru-RU', options)}`;
  };

  const formatPhone = (phone: string) => {
    return (
      <Link href={`tel:${phone}`} color="primary">
        {phone}
      </Link>
    );
  };

  const formatEmail = (email: string) => {
    return (
      <Link href={`mailto:${email}`} color="primary">
        {email}
      </Link>
    );
  };

  const formatPassStatus = (status?: string) => {
    switch (status) {
      case 'ACTIVE':
        return 'Активен';
      case 'PENDING':
        return 'Ожидает активации';
      case 'FAILED':
        return 'Ошибка';
      default:
        return 'Неизвестно';
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        )}
        Детали бронирования
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            {office.name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <EventIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography>
              {formatDate(booking.startTime, booking.timezone)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AccessTimeIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography>
              {formatTimeRange(booking.startTime, booking.endTime, booking.timezone)}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Клиент
          </Typography>
          <Typography>
            {booking.customer_first_name} {booking.customer_last_name}
          </Typography>
          <Typography>
            Телефон: {formatPhone(booking.customer_phone)}
          </Typography>
          {booking.customer_email && (
            <Typography>
              Email: {formatEmail(booking.customer_email)}
            </Typography>
          )}
        </Box>

        {booking.booking_comment && (
          <>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Комментарий
              </Typography>
              <Typography>
                {booking.booking_comment}
              </Typography>
            </Box>
          </>
        )}

        <Divider sx={{ my: 2 }} />

        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Информация о бронировании
          </Typography>
          <Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          Статус:           
            <Chip 
              label={getStatusText(booking.status)}
              color={getStatusColor(booking.status)}
              size="small"
            />
          </Box>
          </Typography>
          <Typography>
            Стоимость: {booking.price / 100} ₽
          </Typography>
          {booking.totalPrice !== booking.price && (
            <Typography>
              Итоговая стоимость: {booking.totalPrice / 100} ₽
            </Typography>
          )}
        </Box>

        {booking.passes && Object.keys(booking.passes).length > 0 && (
          <>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Коды доступа
              </Typography>
              {Object.entries(booking.passes).map(([deviceId, pass]) => (
                <Box 
                  key={deviceId}
                  sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 1,
                    gap: 1
                  }}
                >
                  <LockIcon sx={{ color: 'text.secondary' }} />
                  <Box>
                    <Typography variant="subtitle2">
                      {pass.name}
                    </Typography>
                    {pass.password && (
                      <Typography variant="body2">
                        Код: {pass.password}
                      </Typography>
                    )}
                    <Typography variant="body2" color="text.secondary">
                      Статус: {formatPassStatus(pass.status)}
                    </Typography>
                    {pass.instructions && (
                      <Typography variant="body2" color="text.secondary">
                        {pass.instructions}
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingDetailsDialog; 
export const getStatusText = (status) => {
    switch (status) {
        case 'NEW':
            return 'Подтверждено';
        case 'DRAFT':
            return 'Черновик';
        case 'CANCELLED':
            return 'Отменено';
        default:
            return status;
    }
};
export const getStatusColor = (status) => {
    switch (status) {
        case 'NEW':
            return 'success';
        case 'DRAFT':
            return 'warning';
        case 'CANCELLED':
            return 'error';
        default:
            return 'default';
    }
};

import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, FormControl, InputLabel, Select, MenuItem, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { Calendar as BigCalendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { ru } from 'date-fns/locale';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useAuth } from 'shared-lib';
import { UserService, OfficeService, Office, Booking } from 'shared-lib';
import BookingDetailsDialog from './BookingDetailsDialog';

const locales = {
  'ru': ru,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  resource?: {
    booking: Booking;
    office: Office;
  };
}

const AdminCalendar: React.FC = () => {
  const { user } = useAuth();
  const [adminOffices, setAdminOffices] = useState<Office[]>([]);
  const [selectedOfficeId, setSelectedOfficeId] = useState<string>('all');
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [selectedOffice, setSelectedOffice] = useState<Office | null>(null);

  const userService = UserService.getInstance();
  const officeService = OfficeService.getInstance();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchAdminOffices = async () => {
      if (!user) return;

      try {
        const adminUserData = await userService.getAdminUserData(user.uid);
        const offices = await officeService.fetchAdminOffices(adminUserData.offices);
        setAdminOffices(offices);
        
        if (offices.length === 1) {
          setSelectedOfficeId(offices[0].id);
        }
      } catch (err) {
        console.error("Error fetching admin offices:", err);
        setError('Failed to fetch admin offices');
      } finally {
        setLoading(false);
      }
    };

    fetchAdminOffices();
  }, [user, officeService, userService]);

  useEffect(() => {
    const fetchBookings = async () => {
      if (!user) return;

      try {
        setLoading(true);
        let bookings: Booking[] = [];

        if (selectedOfficeId === 'all') {
          const promises = adminOffices.map(office => 
            officeService.getOfficeBookings(office.id)
          );
          const bookingsArrays = await Promise.all(promises);
          bookings = bookingsArrays.flat();
        } else {
          bookings = await officeService.getOfficeBookings(selectedOfficeId);
        }

        // Convert bookings to calendar events
        const calendarEvents: CalendarEvent[] = bookings.map(booking => {
          const office = adminOffices.find(o => o.id === booking.officeId);
          return {
            id: booking.id,
            title: `${booking.customer_first_name} ${booking.customer_last_name} • ${booking.customer_phone}`,
            start: new Date(booking.startTime),
            end: new Date(booking.endTime),
            resource: {
              booking,
              office: office!
            }
          };
        });

        setEvents(calendarEvents);
      } catch (err) {
        console.error("Error fetching bookings:", err);
        setError('Failed to fetch bookings');
      } finally {
        setLoading(false);
      }
    };

    if (adminOffices.length > 0) {
      fetchBookings();
    }
  }, [selectedOfficeId, adminOffices, officeService, user]);

  const handleSelectEvent = (event: CalendarEvent) => {
    if (event.resource) {
      setSelectedBooking(event.resource.booking);
      setSelectedOffice(event.resource.office);
    }
  };

  const handleCloseDialog = () => {
    setSelectedBooking(null);
    setSelectedOffice(null);
  };

  if (loading) {
    return (
      <Container sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel>Выберите помещение</InputLabel>
          <Select
            value={selectedOfficeId}
            label="Выберите помещение"
            onChange={(e) => setSelectedOfficeId(e.target.value)}
          >
            {adminOffices.length > 1 && (
              <MenuItem value="all">Все помещения</MenuItem>
            )}
            {adminOffices.map((office) => (
              <MenuItem key={office.id} value={office.id}>
                {office.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ 
        height: isMobile ? 'calc(100vh - 200px)' : 'calc(100vh - 250px)',
        '.rbc-calendar': {
          minHeight: '400px'
        },
        '.rbc-toolbar': {
          flexWrap: 'wrap',
          justifyContent: 'center',
          '.rbc-toolbar-label': {
            width: isMobile ? '100%' : 'auto',
            textAlign: 'center',
            margin: isMobile ? '8px 0' : 'inherit'
          },
          '.rbc-btn-group': {
            margin: isMobile ? '4px' : 'inherit'
          }
        },
        '.rbc-event': {
          padding: '2px 4px',
          '.rbc-event-content': {
            fontSize: isMobile ? '12px' : '14px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }
        },
        '.rbc-agenda-view': {
          table: {
            '.rbc-agenda-date-cell, .rbc-agenda-time-cell': {
              fontSize: isMobile ? '12px' : '14px',
              padding: isMobile ? '4px' : '8px'
            },
            '.rbc-agenda-event-cell': {
              fontSize: isMobile ? '12px' : '14px',
              padding: isMobile ? '4px' : '8px'
            }
          }
        }
      }}>
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={isMobile ? 
            [Views.DAY, Views.AGENDA] : 
            [Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]
          }
          defaultView={isMobile ? Views.DAY : Views.WEEK}
          onSelectEvent={handleSelectEvent}
          culture="ru"
          messages={{
            today: 'Сегодня',
            previous: 'Назад',
            next: 'Вперед',
            month: 'Месяц',
            week: 'Неделя',
            day: 'День',
            agenda: 'Список',
            date: 'Дата',
            time: 'Время',
            event: 'Событие',
            noEventsInRange: 'Нет бронирований в выбранном периоде',
            showMore: (total) => `+${total} ещё`
          }}
          eventPropGetter={(event) => {
            const office = event.resource?.office;
            return {
              style: {
                backgroundColor: '#1976d2',
                cursor: 'pointer',
                fontSize: isMobile ? '12px' : '14px'
              },
              title: `${office?.name}\n` +
                     `Клиент: ${event.resource?.booking.customer_first_name} ${event.resource?.booking.customer_last_name}\n` +
                     `Телефон: ${event.resource?.booking.customer_phone}\n` +
                     `Email: ${event.resource?.booking.customer_email || 'не указан'}\n` +
                     `Комментарий: ${event.resource?.booking.booking_comment || 'нет'}`
            };
          }}
          components={{
            event: (props) => (
              <Box sx={{ 
                fontSize: isMobile ? '12px' : '14px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>
                {props.title}
              </Box>
            )
          }}
        />
      </Box>

      {selectedBooking && selectedOffice && (
        <BookingDetailsDialog
          open={true}
          onClose={handleCloseDialog}
          booking={selectedBooking}
          office={selectedOffice}
        />
      )}
    </Container>
  );
};

export default AdminCalendar;